.wrapper {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 118px);
  display: flex;
  place-content: center;
}

.content {
  padding: 32px 0 0 32px;
  width: 800px;
}

.panel {
  padding-left: 32px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  margin-bottom: 32px;
}

.margin-right {
  margin-right: 32px;
}

.panel-title {
  padding: 24px 0px;
  height: 72px;
  display: flex;
  align-items: center;
}

.info-item {
  display: flex;
  min-height: 64px;
  align-items: center;
  max-height: 128px;
}

.info-item + .info-item {
  border-top: 1px solid var(--button-background-color);
}

.flex-wrap {
  display: flex;
  flex-flow: nowrap;
  width: calc(100% - 80px);
}

.info-item-title {
  width: 250px;
}

.info-item-value {
  min-width: 105px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatarContainer {
  width: 48px;
  height: 48px;
  border: 1px solid #ced0d9;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: var(--main-background-color);
}

.public-icon {
  margin-left: auto;
  margin-right: 40px;
}

.accordion {
  margin-bottom: 32px !important;
  padding: 0;
  box-shadow: none;
}

.accordion::before {
  display: none;
}

.accorion-summary {
  padding: 32px;
}

.accorion-summary:hover {
  background: var(--background-darkened-color);
}

.accorion-summary-content {
  margin: 0 !important;
}

.accorion-summary:active {
  background: var(--selected-background-color);
}

.accordion-details {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.margin-bottom {
  margin-bottom: 32px;
}

.user-icon-wrapper {
  border-radius: 4px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--main-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.justify {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 0px 0px;
  align-items: flex-start;
}

.add-icon {
  margin-right: 8px;
}

.add-button {
  margin-bottom: 24px;
}

.lock-btn {
  display: flex;
  justify-content: center;
  width: 64px;
  height: 40px;
  border-radius: 4px;
  margin-right: 16px;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 24px;
}

.popover-button {
  padding: 8px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
}

@media screen and (max-width: 1023px) {
  .content {
    padding: 16px;
    width: auto;
    border: none;
  }

  .flex-wrap {
    flex-flow: row wrap;
  }
}

@media screen and (max-width: 767px) {
  .panel {
    margin-bottom: 24px;
    padding: 24px 0 0 16px;
  }

  .margin-right {
    margin-right: 16px;
  }

  .info-item-title {
    min-width: 150px;
    max-width: 150px;
  }

  .justify {
    padding-right: 16px;
  }

  .accordion {
    margin-bottom: 24px !important;
    padding: 0;
    box-shadow: none;
  }

  .accorion-summary {
    padding: 16px;
  }

  .margin-bottom {
    margin-bottom: 16px;
  }

  .accordion-details {
    padding-left: 14px;
  }
}

@media screen and (max-width: 360px) {
  .info-item-title {
    min-width: 117px;
  }
}
