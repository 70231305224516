.select {
  width: 100% !important;
}

.selectTitleWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectSubtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}
