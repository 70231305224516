.widget-color {
  width: 100%;
}

.set-color {
  margin-top: 32px;
}

.color-title {
  margin-bottom: 8px;
}

.widget-input-label {
  border: none;
  color: rgb(51, 51, 51);
}

.widget-input-label-focused label {
  color: rgb(51, 51, 51);
  opacity: 0.38;
}

.input-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.disable-asterisk {
  display: none;
}

@media screen and (max-width: 1023px) {
  .widget-color {
    padding-bottom: 32px;
  }
}
