.filter {
  margin-bottom: 15px;
}

.filterText {
  margin-left: 8px;
  margin-right: 4px;
}

.popover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: var(--main-background-color);
}

.popoverButtonIcon {
  margin-right: 16px;
}

.popoverButtonIconHidden {
  visibility: hidden;
  opacity: 0;
}