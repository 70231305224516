.container {
  display: flex;
  gap: 32px;
  align-items: center;
  margin-bottom: 16px;
}

.avatarContainer {
  background: var(--main-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  width: 48px;
  height: 48px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatarCircle {
  border: 1px solid #ced0d9;
  border-radius: 50%;
}

.remove-input {
  display: none;
}

.label-button:active {
  pointer-events: none;
}

.label {
  cursor: pointer;
  display: block;
  width: fit-content;
}

.flex-container {
  display: flex;
  align-items: center;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: var(--main-background-color);
}

.gray-avatar {
  background-color: #bdbdbd;
}
