.backdrop {
  background: none;
}

.drawer-paper {
  box-shadow: none;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 0 0 0;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 32px;
}

.create-provider-form {
  padding: 0 32px;
  min-height: calc(100vh - 220px);
}

.arrow-icon {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: var(--link-color);
}

.button-back {
  padding: 0;
  margin-right: 16px;
}

.close-button {
  margin-left: 24px;
  padding: 0;
}

.subtitle {
  margin-bottom: 24px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.input-title {
  margin-bottom: 8px;
}

.input-subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.input-error {
  margin-bottom: 8px;
}

.id-icon path {
  fill: #ced0d9;
  stroke: #ced0d9;
}

.id-icon path:nth-child(1) {
  stroke: none;
}

.id-icon path:nth-child(3) {
  fill: none;
}

.divider {
  width: calc(100% + 64px);
  height: 1px;
  margin-left: -32px;
  margin-bottom: 32px;
  background: var(--button-background-color);
}

.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0 32px;
}

.switch-root {
  margin-left: auto;
}

.select-wrapper {
  position: relative;
}

.save-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.download {
  display: flex;
  justify-content: space-between;
}

.certInput {
  display: none;
}

.certIcon {
  width: 60px;
  height: 60px;
  color: var(--main-grey-color);
}

.certContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}
