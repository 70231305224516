.container {
  padding: 0 32px 28px;
}

.switchWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}

.infoContainer {
  margin-top: 32px;
}

.infoText {
  margin-bottom: 8px;
}

.codeMirror {
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
}

.widgetWrapper {
  margin-top: 32px;
}

.preview {
  margin-bottom: 12px;
}