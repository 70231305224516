.widget {
  background: var(--main-background-color);
  width: 400px;
  padding-bottom: 24px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
}

.widget-title {
  font-size: 12px;
  line-height: 14px;
  color: #9da2b3;
  padding: 16px;
}

.title-container {
  display: flex;
  align-items: center;
  margin: 24px 0 45px;
  width: 100%;
  justify-content: center;
}

.widget-subtitle {
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 22px;
  line-height: 30px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-field {
  min-height: 88;
  color: rgb(51, 51, 51);
}

.widget-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 72px;
  margin-bottom: 16px;
}

.widget-second-button {
  font-weight: 400;
  padding: 6px 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.widget-second-button:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.widget-text {
  text-align: center;
  margin-top: 12px;
}

.providers {
  display: flex;
  gap: 18px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
}

.providerIcon {
  border-radius: 8px;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button-background-color);
  object-fit: cover;
  object-position: center;
}

.preview {
  margin-bottom: 16px;
}

.footer {
  margin-top: 40px;
}

.divider {
  width: calc(100% + 48px);
  height: 1px;
  background: var(--button-background-color);
  margin-left: -24px;
}

@media screen and (max-width: 1023px) {
  .widget {
    width: auto;
    max-width: 396px;
  }
}

