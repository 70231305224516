.emptyContent {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyText {
  margin-bottom: 16px;
}

.emptyIcon {
  width: 50px;
  height: 50px;
}
